import React from 'react'

const CardCartList = () => {
  return (
    <ul className='cart-list'>
      <li>
        {/* eslint-disable-next-line */}
        <a href='#' className='cart-btn-close'>
          ×
        </a>
        <img
          src='/assets/images/content/portfolios/square/1.jpg'
          alt='Thumbnail'
        />
        <div className='cart-content'>
          <p>
            1 x<span>$30</span>
          </p>
          {/* eslint-disable-next-line */}
          <a href='#'>Black Leather Dress</a>
        </div>
      </li>
      <li>
        {/* eslint-disable-next-line */}
        <a href='#' className='cart-btn-close'>
          ×
        </a>
        <img
          src='/assets/images/content/portfolios/square/2.jpg'
          alt='Thumbnail'
        />
        <div className='cart-content'>
          <p>
            1 x<span>$30</span>
          </p>
          {/* eslint-disable-next-line */}
          <a href='#'>Black Leather Dress</a>
        </div>
      </li>
    </ul>
  )
}

export default CardCartList
