import React from 'react'
import { ContentProvider } from '../context/ContextProvider'
import {
  Header,
  Navigation,
  NavTop,
  NavMobile,
  NavMobileTop,
  NavBar,
  BodyWrap,
  Footer,
} from '../config/ComponentsAll'
import NavLinks from '../components/navigation/NavLinks'
import NavMobileLinks from '../components/navigation/NavMobileLinks'
import { FooterProvider } from '../context/FooterProvider'
import { ProductsProvider } from '../context/ProductsProvider'
import ListProducts from './ListProducts'
import { CategoryProvider } from '../context/CategoryProvider'

const Ecostore = ({ children }) => {
  return (
    <ContentProvider>
      <Header>
        <NavMobile>
          <NavMobileLinks />
        </NavMobile>

        <NavMobileTop />

        <Navigation>
          <NavTop />

          <NavBar>
            <NavLinks />
          </NavBar>
        </Navigation>
      </Header>

      <ProductsProvider>
        <BodyWrap>
          <CategoryProvider>
            <ListProducts />
          </CategoryProvider>
        </BodyWrap>
      </ProductsProvider>

      <FooterProvider>
        <Footer>{/* <Bottom /> */}</Footer>
      </FooterProvider>
    </ContentProvider>
  )
}

export default Ecostore
