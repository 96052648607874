/* eslint-disable jsx-a11y/anchor-is-valid */
import HTMLReactParser from 'html-react-parser'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { API_URL } from '../../config/ComponentsAll'
import { FooterContext } from '../../context/contextContent'
import FormContact from '../../pages/componentPages/FormContact'

const Bottom = () => {
  const footerContext = useContext(FooterContext)
  const { contentState } = footerContext
  const [dataFooter, setDatafooter] = useState([])
  const [logo, setLogo] = useState([])
  const [contentFooter, setContentFooter] = useState([])

  useEffect(() => {
    if (contentState !== 0) {
      setDatafooter(contentState.data)
    }
  }, [contentState])

  useEffect(() => {
    if (dataFooter.data && dataFooter.data.attributes.logofooter) {
      setLogo(
        API_URL + dataFooter.data.attributes.logofooter.data.attributes.url,
      )
    }
    if (contentFooter && contentFooter.length === 0 && dataFooter.data) {
      setContentFooter(dataFooter.data.attributes.content)
    }
  }, [contentFooter, dataFooter])

  if (dataFooter.length !== 0) {
    return (
      <Fragment>
        {/*#bottom */}
        <div id='bottom'>
          {/*.container */}
          <div className='container'>
            {/*.footer-widgets */}
            <div className='footer-widgets'>
              {/*.row */}
              <div className='row'>
                <div className='col-sm-4 padding-bottom40'>
                  <div className='widget'>
                    <img
                      src={logo}
                      alt='Logo'
                      style={{ height: 60 }}
                      className='margin-bottom20'
                    />
                    <p>
                      {dataFooter.data.attributes.text_footer_sobre &&
                        HTMLReactParser(
                          dataFooter.data.attributes.text_footer_sobre,
                        )}
                      {/* A ECOMETRIA é resultado da aliança de empresas atuantes e
                      com grande know-how no setor de tecnologia de ponta, com o
                      objetivo de criar soluções sustentáveis e inteligentes de
                      gestão, buscando controle e economia, principalmente nas
                      áreas de Indústria, Saneamento, Energia, Gás, Ambiental e
    Agronegócio.*/}
                    </p>
                  </div>
                  <div className='widget'>
                    <h4 className='widget-title'>Links</h4>

                    <ul style={{ position: 'relative' }}>
                      <li>
                        <a href='/'>HOME</a>
                      </li>
                      <li>
                        <a href='/sobre'>SOBRE</a>
                      </li>
                      <li>
                        <a href='https://ecostore.ecometria.eco.br'>LOJA</a>
                      </li>
                      <li>
                        <a href='/contato'>CONTATO</a>
                      </li>
                      <li>
                        <a href='#'>SERVIÇOS</a>
                        <ul
                          style={{
                            position: 'relative',
                            top: 42,
                            left: -42,
                            width: '100%',
                          }}
                        >
                          <li>
                            <a href='/sms'>M2M/Iot</a>
                          </li>
                          <li>
                            <a href='/ecoweb'>ECOWEB</a>
                          </li>
                          <li>
                            <a href='/econet'>ECONET</a>
                          </li>
                          <li>
                            <a href='/eco-3-t'>ECO3T+</a>
                          </li>
                          <li>
                            <a href='/loja'>LOJA</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-sm-4 padding-bottom40'>
                  <h4>Entre em contato</h4>

                  <FormContact />
                </div>
                <div className='col-sm-4 padding-bottom40'>
                  <div className='widget'>
                    <div className='widget_text'>
                      <h4 className='widget-title'>Informações de contato</h4>
                      <div className='list-icon'>
                        <ul>
                          {contentFooter &&
                            contentFooter.map((contact) => {
                              const { Email, address, phone } = contact
                              return (
                                <div
                                  key={contact.id}
                                  style={{
                                    borderBottom: '1px solid',
                                    borderBottomColor: 'rgba(99, 99, 99, 0.4)',
                                    paddingBottom: 24,
                                  }}
                                >
                                  {phone &&
                                    phone.map((tel) => {
                                      return (
                                        <Fragment key={tel.id}>
                                          <h4 style={{ marginTop: 36 }}>
                                            {' '}
                                            Unidade: {tel.title}
                                          </h4>
                                          <li>
                                            <i className='ion ion-ios-telephone'></i>
                                            {tel.number}
                                          </li>
                                        </Fragment>
                                      )
                                    })}
                                  {Email &&
                                    Email.map((email) => {
                                      return (
                                        <li key={email.id}>
                                          <i className='ion ion-ios-email'></i>
                                          <a href={`mailto:${email.email}`}>
                                            <strong>{email.title}: </strong>
                                            {email.email}
                                          </a>
                                        </li>
                                      )
                                    })}
                                  <li>
                                    <i className='ion ion-map'></i>
                                    {address}
                                  </li>
                                </div>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='widget'>
                    <div className='widget_recent_entries'></div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-4'></div>

                <div className='col-sm-4'></div>
                <div className='col-sm-4'></div>

                {/*.row end */}
              </div>
              {/*.row */}
              <div className='row'></div>
              {/*.row end */}
            </div>
            {/*.footer-widgets end */}
          </div>
          {/*.container end */}
        </div>
        {/*#bottom end */}
      </Fragment>
    )
  }
}
export default Bottom
