import React, { useEffect, useState } from 'react'
import { api, API_URL, TOKEN_API } from '../../config/ComponentsAll'

const Logo = () => {
  const [getLogo, setLogo] = useState([])
  const [logo, setLogoRead] = useState([])

  useEffect(() => {
    async function headerLoad() {
      // eslint-disable-next-line
      const contentHeader = await api
        .get('/api/header', {
          headers: {
            Authorization: `bearer ${TOKEN_API}`,
          },

          params: {
            populate: 'deep',
          },
        })
        .then((ress) => {
          setLogo(ress.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    headerLoad()
  }, [])
  const { data } = getLogo

  useEffect(() => {
    if (data) setLogoRead(data.attributes.logo.data.attributes.url)
  }, [data])

  if (data) {
    return (
      <div className='navbar-logo'>
        <a href='/'>
          <img src={API_URL + logo} alt='Ecometria' />
        </a>{' '}
        {/* site logo */}
      </div>
    )
  }
}

export default Logo
