import React, { Fragment } from 'react'
import Logo from '../header/Logo'
import SecondaryNav from './SecondaryNav'

const NavMobileTop = () => {
  // nav-mobile-open
  function onClickMobile() {
    //if (document.body.classList.contains('nav-mobile-open')) {
    //   document.body.classList.remove('nav-mobile-open')
    // } else {
    //    document.body.classList.add('nav-mobile-open')
    //  }
  }
  return (
    <Fragment>
      {/* #nav-mobile-top */}
      <div id='nav-mobile-top'>
        {/* .container */}
        <div className='container-fluid'>
          <div className='navmenu'>
            <button
              type='button'
              onClick={() => {
                onClickMobile()
              }}
              className='navbar-btn-toggle'
            >
              <i className='ion ion-navicon'></i>
            </button>

            <Logo />

            <SecondaryNav data={{ visible: 'hide' }} />
          </div>
        </div>
        {/* .container end */}
      </div>
      {/* #nav-mobile-top end */}
    </Fragment>
  )
}

export default NavMobileTop
