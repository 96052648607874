import React from 'react'

const CardCartFooter = () => {
  return (
    <div className='cart-footer'>
      <a href='cart.html' className='btn-custom btn-grey'>
        View Cart
      </a>
      {/* eslint-disable-next-line */}
      <a href='#' className='btn-custom'>
        Checkout
      </a>
    </div>
  )
}

export default CardCartFooter
