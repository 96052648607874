import React from 'react'

const CartHeading = () => {
  return (
    <div className='cart-heading'>
      <p className='cart-count'>2 item(s)</p>
      <p className='cart-price'>$79.00</p>
    </div>
  )
}

export default CartHeading
