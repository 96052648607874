import P from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { TOKEN_API } from '../config/ComponentsAll'
import { loadContent } from './contentAction'
import { ContentData } from './contentData'
import ContentReducer from './ContentReducer'
import { CategoryContext } from './contextContent'

export const CategoryProvider = ({ children }) => {
  const [categoryState, contentDispatch] = useReducer(
    ContentReducer,
    ContentData,
  )
  useEffect(() => {
    const abortController = new AbortController()

    loadContent(contentDispatch, 'api/categories', {
      headers: {
        Authorization: `bearer ${TOKEN_API}`,
      },

      params: {
        populate: 'deep',
      },
    })
      .then((dispatch) => {
        dispatch()
      })
      .catch((error) => console.log(error.message))

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <CategoryContext.Provider value={{ categoryState, contentDispatch }}>
      {children}
    </CategoryContext.Provider>
  )
}

CategoryProvider.propType = {
  children: P.node.isRequired,
}
