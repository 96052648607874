import React from 'react'
import loadgif from './fancybox_loading@2x.gif'

const LoadingGif = () => {
  return (
    <div className='loading'>
      <img src={loadgif} alt='Loading' />
    </div>
  )
}

export default LoadingGif
