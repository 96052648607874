import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

const Navigation = ({ children }) => {
  const [scroll, setScroll] = useState(true)
  const [activeClass, setActiveClass] = useState('')

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
        setActiveClass('affix')
      } else {
        setActiveClass('')
      }
    })
    // eslint-disable-next-line
  }, [])

  // (dataNavigation) && dataNavigation.map((nav) => {

  //     const { order, id, title, type, path, audience, parent, uiRouterKey } = nav;
  //     // console.log(title)
  // })
  return (
    <Fragment>
      {/* #navigation */}
      <nav id='navigation' className={activeClass}>
        {children}
      </nav>
      {/* #navigation end */}
    </Fragment>
  )
}
export default Navigation
