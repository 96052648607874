import React, { useEffect, useState } from 'react'
import { TOKEN_API, api } from '../../config/ComponentsAll'

const NavTop = () => {
  const [headerData, setHeaderData] = useState([])
  const [socialMedia, setSocialMedia] = useState([])

  useEffect(() => {
    if (headerData.length === 0) {
      async function headerLoad() {
        const contentHeader = await api
          .get('/api/header', {
            headers: {
              Authorization: `bearer ${TOKEN_API}`,
            },
            params: {
              populate: 'deep',
            },
          })
          .then((ress) => setHeaderData(ress.data.data))
          .catch((error) => console.log(error.message))
      }
      headerLoad()
    }
  }, [])

  useEffect(() => {
    if (
      headerData.length !== 0 &&
      socialMedia.length === 0 &&
      headerData.attributes.social_network &&
      headerData.attributes.social_network.length !== 0
    ) {
      setSocialMedia(headerData.attributes.social_network)
    }
  }, [headerData])

  return (
    <div className='navtop'>
      {/* .container */}
      <div className='container'>
        <div className='nav-txt'>
          <p>
            <i className='ion ion-android-phone-portrait'></i> Tem dúvidas?
            Ligue para:{' '}
            <strong>
              {/* eslint-disable-next-line */}
              <a href='tel:+55114305-9004'>11 4305-9004</a>
            </strong>
          </p>
        </div>

        <div className='nav-socials'>
          {socialMedia.length !== 0 &&
            socialMedia.map((social) => {
              return (
                <a
                  key={social.id}
                  href={social.url}
                  title={social.name}
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className={`ion ion-social-${social.componentTitle}`}></i>
                </a>
              )
            })}
        </div>
      </div>
      {/* .container end */}
    </div>
  )
}

export default NavTop
