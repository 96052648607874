import React, { Fragment, useContext, useEffect, useState } from 'react'
import { FooterContext } from '../../context/contextContent'

const Footer = ({ children }) => {
  const footerContext = useContext(FooterContext)
  const { contentState } = footerContext
  const [dataFooter, setDataFooter] = useState([])
  const [color, setColor] = useState('#a9a9a9')
  const [scroll, setScroll] = useState(true)
  const [activeClass, setActiveClass] = useState('')

  useEffect(() => {
    if (contentState.data.length !== 0) {
      setDataFooter(contentState.data)
    }
  }, [contentState.data])

  useEffect(() => {
    if (
      dataFooter.data !== undefined &&
      dataFooter.data.attributes !== undefined
    ) {
      if (
        dataFooter.data.attributes.background !== undefined &&
        dataFooter.data.attributes.background !== '#000000'
      ) {
        setColor('#000000')
      } else {
        setColor('#a9a9a9')
      }
    }
  }, [dataFooter])
  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 150
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
        setActiveClass('visible')
      } else {
        setActiveClass('')
      }
    })
    // eslint-disable-next-line
  }, [])
  return (
    <Fragment>
      {children}
      {/*#footer */}
      {dataFooter.length !== 0 && dataFooter !== 0 && (
        <footer
          id='footer'
          style={{
            backgroundColor: dataFooter.data.attributes.background,
            color: color,
          }}
        >
          {/*.container */}
          <div className='container'>
            <Fragment>
              <div className='footer-nav'>
                <p className='padding-top20'>
                  {dataFooter.data.attributes.copyright}
                </p>
              </div>

              <div className='footer-socials'>
                <ul>
                  {dataFooter.data.attributes.RedesSociais.length !== 0 &&
                    dataFooter.data.attributes.RedesSociais.map((social) => {
                      return (
                        <li key={social.id}>
                          <a
                            href={social.url}
                            target='_blank'
                            title={social.name}
                            rel='noreferrer'
                          >
                            <i
                              className={`ion ion-social-${social.name.toLowerCase()}`}
                            ></i>
                          </a>
                        </li>
                      )
                    })}
                  {/*<li><a href="#" title="Twitter"><i className="ion ion-social-twitter"></i></a></li>*/}
                  {/*<li><a href="#" title="Google Plus"><i className="ion ion-social-googleplus"></i></a></li>
                <li><a href="#" title="Pinterest"><i className="ion ion-social-pinterest"></i></a></li>
                <li><a href="#" title="Instagram"><i className="ion ion-social-instagram"></i></a></li>
                <li><a href="#" title="Dribbble"><i className="ion ion-social-dribbble"></i></a></li>
           */}{' '}
                </ul>
              </div>
            </Fragment>
          </div>
          {/*.container end */}
        </footer>
      )}
      {/*#footer end */}
      {/* eslint-disable-next-line */}
      <a href='#' className={`scrollup ${activeClass}`} title='Back to Top!'>
        <i className='ion ion-android-arrow-up'></i>
      </a>{' '}
      {/*Back to Top */}
      {/*[if lt IE 8]>
<div className="browser-notice">
    <div className="container">
        <div className="text">
            <h1>Internet Explorer Out To Date</h1>
            <p>Please update your Internet Explorer browser with a newer version (Internet Explorer 8 above) now!</p>
            <span>You can download it <a href="http://windows.microsoft.com/en-us/internet-explorer/download-ie" target="_blank">here....</a></span>
        </div>
    </div>
</div>
<![endif]*/}
    </Fragment>
  )
}
export default Footer
