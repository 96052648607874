import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ContextContent } from '../../context/contextContent'
import { useParams } from 'react-router-dom'
import Breadcrumb from './Breadcrumb'
import { lazy } from 'react'
import { api, TOKEN_API } from '../../config/ComponentsAll'
import LoadingGif from '../Loading'

const Slider = lazy(() => import('../SliderPro/Slider'))
const SubHeader = lazy(() => import('./SubHeader'))
const Header = ({ children }) => {
  const theContent = useContext(ContextContent)
  const [headerData, setHeaderData] = useState([])
  const { contentState } = theContent
  const [headerTransparent, setHeaderTransparent] = useState([])
  const [classHeader, setClassHeader] = useState('header-page')
  const url = useParams().slug
  const [urlData, setUrlData] = useState('')
  const [getBreadcrumb, setBreadcrumb] = useState(false)
  const [getSubHeader, setSubHeader] = useState(false)
  const [title, setTitle] = useState()
  const [subtitle, setSubTitle] = useState()
  const [description, setDescription] = useState()
  const [slug, setSlug] = useState()
  const [imgfeature, setImgFeature] = useState()
  const [classPage] = useState()

  useEffect(() => {
    if (url === undefined) setUrlData('/')
    if (url !== undefined) setUrlData(url)
  }, [url])

  useEffect(() => {
    if (headerData.length === 0) {
      async function headerRed() {
        api
          .get('/api/header', {
            headers: {
              Authorization: `bearer ${TOKEN_API}`,
            },

            params: {
              populate: 'deep',
            },
          })
          .then((res) => {
            setHeaderData(res.data.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      headerRed()
    }
  }, [headerData.length])

  useEffect(() => {
    if (contentState.data !== 0) {
      // eslint-disable-next-line
      contentState.data.map((data) => {
        if (data.path === urlData) {
          if (data.related && data.related.Components.length !== 0) {
            setClassHeader('')
          } else {
            setClassHeader('header-page')
          }

          if (data.related) {
            const {
              related: { title, subtitle, slug, description, imgfeature },
            } = data
            setTitle(title)
            setSubTitle(subtitle)
            setSlug(slug)
            setDescription(description)
            setImgFeature(imgfeature)
          }
        }
        /** Component Breadcrumb */
        if (data.related && data.related.slug === url) {
          if (data.related.Breadcrumb === true) {
            setBreadcrumb(data.related.Breadcrumb)
          } else {
            setBreadcrumb(false)
          }
        }

        /** Component SubHeader */

        if (data.related && data.related.slug === url) {
          if (data.related.SubHeader === true) {
            setSubHeader(data.related.SubHeader)
          } else {
            setSubHeader(false)
          }
        }
      })
    }
    if (headerData) {
      if (
        headerData &&
        headerData.attributes &&
        headerData.attributes.header_class
      ) {
        setHeaderTransparent(headerData.attributes.header_class)
      } else {
        setHeaderTransparent('')
      }
    }
  }, [url, contentState, urlData, headerData])

  return (
    <Fragment>
      <header
        id='header'
        className={`${classHeader + ' ' + headerTransparent + ' ' + classPage}`}
      >
        {children}
        <Suspense fallback={<LoadingGif />}>
          <Slider />
        </Suspense>
        {getBreadcrumb && (
          <Breadcrumb title={title} subtitle={subtitle} slug={slug} />
        )}
        {getSubHeader && (
          <Suspense>
            <SubHeader
              title={title}
              subtitle={subtitle}
              slug={slug}
              description={description}
              imgfeature={imgfeature}
            />
          </Suspense>
        )}
      </header>
    </Fragment>
  )
}

export default Header
