import React from 'react'

const IconMenu = () => {
  return (
    <div className='navbar-btn'>
      {/*eslint-disable-next-line */}
      <a href='#' className='btn-cart'>
        <i className='ion ion-bag'></i>
      </a>
      {/*eslint-disable-next-line */}
      <a href='#' className='btn-search'>
        <i className='ion ion-ios-search-strong'></i>
      </a>
      {/*eslint-disable-next-line */}
      <a href='#' className='btn-user'>
        <i className='ion ion-person'></i>
      </a>
    </div>
  )
}

export default IconMenu
