import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API_URL, TOKEN_API } from '../config/ComponentsAll'
import { CategoryContext, ProductContext } from '../context/contextContent'
import HTMLResactParser from 'html-react-parser'
import axios from 'axios'

const ListProducts = () => {
  const params = useParams()
  const productContext = useContext(ProductContext)
  const categoriesContext = useContext(CategoryContext)
  const { categoryState } = categoriesContext
  const { contentState } = productContext

  const [dataProducts, setDataProducts] = useState([])

  useEffect(() => {
    if (contentState.data) setDataProducts(contentState.data)
  }, [contentState.data])

  const [dataCategory, setDataCategory] = useState([])

  //request data categories
  useEffect(() => {
    if (categoryState.data) setDataCategory(categoryState.data)
    let hash = window.location.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView(true)
      }
    }
  }, [categoryState.data])

  const [mensagem, setMensage] = useState('')
  //request quotes
  function handleSubmit(e) {
    const data = e.currentTarget.elements
    const {
      name,
      lastname,
      status,
      product,
      product_url,
      company,
      subject,
      email,
      phone,
      message,
    } = data

    const dataform = {
      status: status.value,
      product: product.value,
      product_url: product_url.value,
      company: company.value,
      name: name.value,
      lastname: lastname.value,
      subject: subject.value,
      email: email.value,
      phone: phone.value,
      mensagem: message.value,
    }

    e.preventDefault()

    axios
      .post('https://api.ecometria.eco.br/api/quotes', {
        data: dataform,

        headers: {
          Authorization: `bearer ${TOKEN_API}`,
        },
      })
      .then(function (response) {
        if (response.status === 200)
          setMensage(
            'Sua mensagem foi enviada com sucesso. Em breve entraremos em contato!',
          )
        console.log(response)
      })
      .catch(function (response) {
        if (response.status !== 200)
          setMensage(
            'Houve um erro ao tentar enviar sua mensagem. Por favor tente novamente mais tarde',
          )
        console.log(response)
      })
  }

  //end request quotes

  if (params.slug) {
    return (
      <div id='content' className='single-product'>
        {/* .container */}
        {dataProducts.data &&
          // eslint-disable-next-line
          dataProducts.data.map((product) => {
            if (product.attributes.slug === params.slug) {
              return (
                <div key={product.id} className='container'>
                  <div className='main-content'>
                    {/* .post-single-txt */}

                    <div className='post-single-txt row'>
                      <div className='col-sm-7'>
                        <div className='post-thumbnail post-single-thumbnail'>
                          {/* .row */}
                          <div className='row'>
                            <div className='col-md-2 hidden-xs hidden-sm'>
                              <div className='carousel-slider carousel-product-thumbnails-nav'>
                                {product.attributes.image.data &&
                                  product.attributes.image.data.map(
                                    (imgdata) => {
                                      return (
                                        <div
                                          key={imgdata.id}
                                          className='post-item'
                                        >
                                          <img
                                            src={
                                              API_URL +
                                              imgdata.attributes.formats
                                                .thumbnail.url
                                            }
                                            alt='Thumbnail'
                                          />
                                        </div>
                                      )
                                    },
                                  )}
                              </div>
                            </div>

                            <div className='col-md-10'>
                              <div className='carousel-slider carousel-product-thumbnails'>
                                {product.attributes.image.data &&
                                  product.attributes.image.data.map(
                                    (imgdata) => {
                                      return (
                                        <div
                                          key={imgdata.id}
                                          className='post-item'
                                        >
                                          {/* 1 */}
                                          <figure className=''>
                                            <a
                                              href={
                                                API_URL + imgdata.attributes.url
                                              }
                                              className='post-item-img fancybox'
                                            >
                                              <img
                                                src={
                                                  API_URL +
                                                  imgdata.attributes.formats
                                                    .large.url
                                                }
                                                alt={imgdata.attributes.name}
                                                width='560'
                                                height='700'
                                              />
                                            </a>
                                            <div className='easyzoom-flyout'></div>
                                          </figure>
                                        </div>
                                      )
                                    },
                                  )}
                              </div>
                            </div>
                          </div>
                          {/* .row end */}
                        </div>
                      </div>

                      <div className='col-sm-5 col-lg-4 col-lg-offset-1'>
                        <div className='entry-content post-single-content'>
                          <h1 className='entry-title post-single-title'>
                            {product.attributes.title}
                          </h1>

                          <div className='post-content-price hide'>
                            <p className='price-discount'>$79.90</p>
                            <p className='price-original'>$149.90</p>
                          </div>

                          <div className='post-content-desc'>
                            {product.attributes.description &&
                              HTMLResactParser(product.attributes.description)}
                          </div>

                          <div className='btn-add-cart '>
                            <a
                              href='#product-tab2'
                              className='btn-custom'
                              data-toggle='tab'
                            >
                              Solicitar Orçamento
                            </a>
                          </div>

                          {/* <ul className="post-content-shares hide">
                                                    <li className="link-wishlist">
                                                        <a href="#"><i className="ion ion-android-favorite"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i className="ion ion-social-facebook"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i className="ion ion-social-twitter"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i className="ion ion-social-googleplus"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i className="ion ion-social-pinterest"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i className="ion ion-android-mail"></i></a>
                                                    </li>
                                                </ul> */}
                        </div>
                      </div>
                    </div>
                    {/* .post-single-txt end */}

                    {/*<!-- .post-single-tabs */}
                    <div className='post-single-tabs'>
                      {/* .row */}
                      <div className='row'>
                        <div className='col-sm-12 col-lg-10 col-lg-offset-1'>
                          <ul className='nav nav-tabs affa-nav-tabs'>
                            <li>
                              <a href='#product-tab1' data-toggle='tab'>
                                Informações Adicionais
                              </a>
                            </li>
                            <li>
                              <a
                                href='#product-tab2'
                                data-toggle='tab'
                                className='btn btn-white'
                              >
                                Solicitar Orçamento
                              </a>
                            </li>
                            {/* <li><a href="#product-tab3 " data-toggle="tab">Reviews (5)</a></li> */}
                          </ul>

                          <div className='tab-content'>
                            <div
                              className='tab-pane fade active in'
                              id='product-tab1'
                            >
                              {/** formulario de orçamento */}
                              <div className='row'>
                                <div className='col-lg-10 col-lg-offset-1'>
                                  <div className='post-heading-center margin-top40'>
                                    <h2>Informações Tecnicas</h2>
                                  </div>
                                  <table>
                                    <tbody>
                                      {product.attributes.infoproduct &&
                                        product.attributes.infoproduct
                                          .AdditionalInfo &&
                                        product.attributes.infoproduct.AdditionalInfo.map(
                                          (addInfo) => {
                                            return (
                                              <tr key={addInfo.id}>
                                                <th>{addInfo.title}</th>
                                                <td>{addInfo.value}</td>
                                              </tr>
                                            )
                                          },
                                        )}
                                    </tbody>
                                  </table>
                                  <div className='sep-border-icon margin-top40 margin-bottom40'>
                                    <i className='ion ion-ios-arrow-down'></i>
                                  </div>
                                </div>
                              </div>
                              <div className='row padding-top80'>
                                <div className='container'>
                                  <div className='post-heading-center margin-top40'>
                                    <h2>Descrição completa</h2>
                                  </div>
                                  {product.attributes.text &&
                                    HTMLResactParser(product.attributes.text)}
                                </div>
                              </div>
                            </div>

                            <div className='tab-pane fade' id='product-tab2'>
                              <div className='affa-callout callout-success'>
                                <h2> {mensagem && mensagem}</h2>
                              </div>
                              <form
                                method='post'
                                onSubmit={handleSubmit}
                                className='affa-form-contact form-contact2- margin-bottom50 submit-form-contact'
                              >
                                <div className='row'>
                                  <div className='col-sm-6'>
                                    <input
                                      type='hidden'
                                      name='subject'
                                      value={`Solicitação de orçamento do produto ${product.attributes.title}`}
                                    />
                                    <input
                                      type='hidden'
                                      name='status'
                                      value='Pendente'
                                    />
                                    <input
                                      type='hidden'
                                      name='product'
                                      value={product.attributes.title}
                                    />
                                    <input
                                      type='hidden'
                                      name='product_url'
                                      value={`https://e-com.ecometria.com.br/ecostore/product/${product.attributes.slug}`}
                                    />
                                    <input
                                      type='text'
                                      name='name'
                                      required
                                      placeholder='Seu nome'
                                    />
                                  </div>
                                  <div className='col-sm-6'>
                                    <input
                                      type='text'
                                      name='lastname'
                                      required
                                      placeholder='Ultimo nome'
                                    />
                                  </div>
                                  <div className='col-sm-12'>
                                    <input
                                      type='text'
                                      name='email'
                                      required
                                      placeholder='Seu email (*)'
                                    />
                                  </div>

                                  <div className='col-sm-4'>
                                    <input
                                      type='text'
                                      name='phone'
                                      placeholder='Seu telefone'
                                    />
                                  </div>
                                  <div className='col-sm-12'>
                                    <input
                                      type='text'
                                      name='company'
                                      placeholder='Nome da Empresa'
                                    />
                                  </div>
                                </div>
                                <textarea
                                  name='message'
                                  id='contact-message'
                                  placeholder='Sua mensagem (*)'
                                ></textarea>
                                <div className='text-center'>
                                  <input
                                    type='submit'
                                    name='submit'
                                    value='Enviar mensagem'
                                  />
                                </div>
                                <div className='submit-status'></div>
                              </form>
                            </div>

                            <div
                              className='tab-pane fade hide'
                              id='product-tab3'
                            >
                              <div id='reviews'>
                                <div id='comments'>
                                  <h2>
                                    <span>5 Reviews</span> for this product
                                  </h2>
                                  <ol className='commentlist'>
                                    <li className='comment'>
                                      {/* 1 */}
                                      <div className='comment_container'>
                                        <img
                                          src='images/content/avatars/2.jpg'
                                          alt='Avatar'
                                          className='avatar'
                                        />
                                        <div className='comment-text'>
                                          <div className='comment-text-header'>
                                            <div className='star-rating'>
                                              <span style={{ width: '100%' }}>
                                                5 out of 5
                                              </span>
                                            </div>
                                            <div className='meta'>
                                              <strong>John Barbatos</strong>
                                              <time dateTime='2017-3-10T16:35:45+00:00'>
                                                March 10, 2017 at 4:35 pm
                                              </time>
                                            </div>
                                          </div>
                                          <div className='description'>
                                            <p>Perfect Hoodie for a Ninja!</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className='comment'>
                                      {/* 2 */}
                                      <div className='comment_container'>
                                        <img
                                          src='images/content/avatars/1.jpg'
                                          alt='Avatar'
                                          className='avatar'
                                        />
                                        <div className='comment-text'>
                                          <div className='comment-text-header'>
                                            <div className='star-rating'>
                                              <span style={{ width: '100%' }}>
                                                5 out of 5
                                              </span>
                                            </div>
                                            <div className='meta'>
                                              <strong>Mugiwara Kiwolen</strong>
                                              <time dateTime='2017-3-10T16:35:45+00:00'>
                                                March 10, 2017 at 4:35 pm
                                              </time>
                                            </div>
                                          </div>
                                          <div className='description'>
                                            <p>
                                              I have lots of hoodies, but none
                                              is as cool as this one!
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className='comment'>
                                      {/* 3 */}
                                      <div className='comment_container'>
                                        <img
                                          src='images/content/avatars/3.jpg'
                                          alt='Avatar'
                                          className='avatar'
                                        />
                                        <div className='comment-text'>
                                          <div className='comment-text-header'>
                                            <div className='star-rating'>
                                              <span style={{ width: '80%' }}>
                                                4 out of 5
                                              </span>
                                            </div>
                                            <div className='meta'>
                                              <strong>Bebbh Dudduls</strong>
                                              <time dateTime='2017-3-10T16:35:45+00:00'>
                                                March 10, 2017 at 4:35 pm
                                              </time>
                                            </div>
                                          </div>
                                          <div className='description'>
                                            <p>
                                              I love hoodies, and ninjas, so
                                              what could be better than a ninja
                                              hoodie? Not much, if you ask me!
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className='comment'>
                                      {/* 4 */}
                                      <div className='comment_container'>
                                        <img
                                          src='images/content/avatars/4.jpg'
                                          alt='Avatar'
                                          className='avatar'
                                        />
                                        <div className='comment-text'>
                                          <div className='comment-text-header'>
                                            <div className='star-rating'>
                                              <span style={{ width: '100%' }}>
                                                5 out of 5
                                              </span>
                                            </div>
                                            <div className='meta'>
                                              <strong>Rendy Jagerjack</strong>
                                              <time dateTime='2017-3-10T16:35:45+00:00'>
                                                March 10, 2017 at 4:35 pm
                                              </time>
                                            </div>
                                          </div>
                                          <div className='description'>
                                            <p>
                                              This is the most bombastic hoodie
                                              in this shop, it’s soft and has
                                              the sly WooThemes ninja on it.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className='comment'>
                                      {/* 5 */}
                                      <div className='comment_container'>
                                        <img
                                          src='images/content/avatars/2.jpg'
                                          alt='Avatar'
                                          className='avatar'
                                        />
                                        <div className='comment-text'>
                                          <div className='comment-text-header'>
                                            <div className='star-rating'>
                                              <span style={{ width: '20%' }}>
                                                1 out of 5
                                              </span>
                                            </div>
                                            <div className='meta'>
                                              <strong>Mugiwara Kiwolen</strong>
                                              <time dateTime='2017-3-10T16:35:45+00:00'>
                                                March 10, 2017 at 4:35 pm
                                              </time>
                                            </div>
                                          </div>
                                          <div className='description'>
                                            <p>
                                              This only gets 1 star because I
                                              don’t have this yet. I want it
                                              now!
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ol>
                                </div>

                                <div id='review_form_wrapper'>
                                  <div id='review_form'>
                                    <div
                                      id='respond'
                                      className='comment-respond'
                                    >
                                      <h3 className='comment-reply-title'>
                                        Add a Review
                                      </h3>
                                      <form
                                        action='#'
                                        method='post'
                                        className='comment-form'
                                      >
                                        <div className='row'>
                                          <div className='col-sm-6'>
                                            <input
                                              type='text'
                                              name='author'
                                              id='author'
                                              placeholder='Your Name'
                                            />
                                          </div>
                                          <div className='col-sm-6'>
                                            <input
                                              type='text'
                                              name='email'
                                              id='email'
                                              placeholder='Email Address'
                                            />
                                          </div>
                                        </div>
                                        <div className='comment-form-comment'>
                                          <textarea
                                            name='comment'
                                            id='comment'
                                            rows='10'
                                            placeholder='Your Review'
                                          ></textarea>
                                        </div>
                                        <div className='comment-form-rating'>
                                          <label>Your Rating</label>
                                          <p className='stars'>
                                            <span>
                                              {/* eslint-disable-next-line */}
                                              <a className='star-1' href='#'>
                                                1
                                              </a>
                                              {/* eslint-disable-next-line */}
                                              <a className='star-2' href='#'>
                                                2
                                              </a>
                                              {/* eslint-disable-next-line */}
                                              <a className='star-3' href='#'>
                                                3
                                              </a>
                                              {/* eslint-disable-next-line */}
                                              <a className='star-4' href='#'>
                                                4
                                              </a>
                                              {/* eslint-disable-next-line */}
                                              <a className='star-5' href='#'>
                                                5
                                              </a>
                                            </span>
                                          </p>
                                        </div>
                                        <input
                                          name='submit'
                                          type='submit'
                                          id='submit'
                                          className='submit'
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* .row end */}
                    </div>
                    {/*<!-- .post-single-tabs end */}

                    {/* .post-single-meta */}
                    <div className='entry-meta post-single-meta'>
                      <div className='row'>
                        <div className='col-sm-12 col-lg-10 col-lg-offset-1'>
                          <p>
                            <strong>SKU:</strong> {product.id}
                          </p>
                          <p>
                            <strong>Categories:</strong>{' '}
                            {/* eslint-disable-next-line */}
                            <a href='#'>
                              {product.attributes.category.data.attributes.name}
                            </a>
                          </p>
                          <p>
                            <strong>Tags:</strong>{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .post-single-meta end */}

                    {/* .post-single-related */}

                    <div className='post-single-related margin-bottom40'>
                      <div className='post-related-nav carousel-nav-btn'>
                        <h4>Produtos relacionados</h4>
                        <div className='post-nav-link'>
                          {/* eslint-disable-next-line */}
                          <a href='#' className='nav-prev'>
                            <i className='ion ion-ios-arrow-left'></i>
                          </a>
                          {/* eslint-disable-next-line */}
                          <a href='#' className='nav-next'>
                            <i className='ion ion-ios-arrow-right'></i>
                          </a>
                        </div>
                      </div>

                      {/*<!-- .carousel-slider */}
                      <div className='carousel-slider carousel-nav-content post-related-slider row'>
                        {dataProducts.data &&
                          // eslint-disable-next-line
                          dataProducts.data.map((related) => {
                            if (
                              related.attributes.category.data.attributes
                                .uid ===
                                product.attributes.category.data.attributes
                                  .uid &&
                              related.attributes.title !==
                                product.attributes.title
                            ) {
                              return (
                                <div key={related.id} className='col-sm-3'>
                                  {/* 1 */}
                                  <div className='affa-product'>
                                    <figure className='post-thumbnail post-item-thumbnail'>
                                      {related.attributes.image.data[0] && (
                                        <a
                                          href={`/ecostore/product/${related.attributes.slug}`}
                                        >
                                          <div className='post-thumbnail-overlay'>
                                            <img
                                              src={
                                                API_URL +
                                                related.attributes.image.data[0]
                                                  .attributes.formats.medium.url
                                              }
                                              alt='Thumbnail'
                                            />
                                          </div>
                                          <img
                                            src={
                                              API_URL +
                                              related.attributes.image.data[0]
                                                .attributes.formats.thumbnail
                                                .url
                                            }
                                            alt='Thumbnail'
                                          />
                                        </a>
                                      )}
                                    </figure>

                                    <div className='post-item-txt'>
                                      <p className='post-item-cats'>
                                        {
                                          related.attributes.category.data
                                            .attributes.name
                                        }
                                      </p>
                                      <h4 className='entry-title post-item-title'>
                                        <a
                                          href={`/ecostore/product/${related.attributes.slug}`}
                                        >
                                          {related.attributes.title}
                                        </a>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          })}
                      </div>
                      {/* .carousel-slider end */}
                    </div>
                    {/* .post-single-related end */}
                  </div>
                </div>
              )
            }
          })}
      </div>
    )
  } else {
    return (
      <div className='padding-top100'>
        <div id='content' className='archive-product bg-white padding-bottom0'>
          {/* .container */}
          <div className='container padding-bottom40'>
            <div className='post-heading-left3'>
              <h4>
                <strong>Categorias</strong>
              </h4>
            </div>
            {/* .row */}
            <div className='row margin-bottom20'>
              {dataCategory.data
                ? // eslint-disable-next-line
                  dataCategory.data.map((cat) => {
                    if (cat.attributes.products.data.length !== 0) {
                      return (
                        <div key={cat.id} className='col-sm-4'>
                          {' '}
                          {/* 1 */}
                          <figure className='affa-products-cat'>
                            <a
                              href={`/ecostore/${cat.attributes.uid}#category`}
                            >
                              {cat.attributes.imagecategory.data !== null ? (
                                <img
                                  src={
                                    API_URL +
                                    cat.attributes.imagecategory.data.attributes
                                      .url
                                  }
                                  alt='Thumbnail'
                                />
                              ) : (
                                <img
                                  src={
                                    API_URL +
                                    cat.attributes.imagecategory.data.attributes
                                      .url
                                  }
                                  style={{ height: '360px' }}
                                  alt='Thumbnail'
                                />
                              )}
                              <div className='products-cat-overlay'>
                                <div className='products-cat-txt'>
                                  <h4>{cat.attributes.name}</h4>
                                </div>
                              </div>
                            </a>
                          </figure>
                        </div>
                      )
                    }
                  })
                : ''}
            </div>
            {/* .row end */}
            {params.category && (
              <div id='category' className='row padding-top40'>
                <div className='post-heading-left3'>
                  <h4>
                    <strong>Por categoria</strong>
                  </h4>
                </div>
                <div className='isotope-row row margin-bottom20'>
                  {dataCategory.data &&
                    dataCategory.data.map((cat) => {
                      return (
                        <Fragment key={cat.id}>
                          {cat.attributes.uid === params.category &&
                            cat.attributes.products &&
                            cat.attributes.products.data.map((prod) => {
                              return (
                                <div
                                  key={prod.id}
                                  className='col-sm-6 col-md-3'
                                >
                                  {' '}
                                  {/* 1 */}
                                  <div
                                    className='affa-product animation'
                                    data-animation='animation-fade-in-up'
                                  >
                                    <figure className='post-thumbnail post-item-thumbnail'>
                                      <a
                                        href={`/ecostore/product/${prod.attributes.slug}`}
                                      >
                                        <div className='post-thumbnail-overlay'>
                                          {prod.attributes.image.data && (
                                            <img
                                              key={
                                                prod.attributes.image.data[0].id
                                              }
                                              src={
                                                API_URL +
                                                prod.attributes.image.data[0]
                                                  .attributes.url
                                              }
                                              alt='Thumbnail'
                                            />
                                          )}
                                        </div>
                                        {prod.attributes.image.data && (
                                          <img
                                            key={
                                              prod.attributes.image.data[0].id
                                            }
                                            src={
                                              API_URL +
                                              prod.attributes.image.data[0]
                                                .attributes.formats.medium.url
                                            }
                                            alt='Thumbnail'
                                          />
                                        )}
                                      </a>
                                    </figure>
                                    <div className='post-item-txt'>
                                      <p className='post-item-cats'>
                                        Categoria:{' '}
                                        {
                                          prod.attributes.category.data
                                            .attributes.name
                                        }
                                      </p>
                                      <h4 className='entry-title post-item-title'>
                                        {/* eslint-disable-next-line */}
                                        <a href='#'>{prod.attributes.title}</a>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                        </Fragment>
                      )
                    })}
                </div>
              </div>
            )}

            <div className='post-heading-left3 '>
              <h4>
                <strong>Em Destaque</strong>
              </h4>
            </div>

            {/* .row */}
            <div className='isotope-row row margin-bottom20 '>
              {dataProducts.data &&
                // eslint-disable-next-line
                dataProducts.data.map((featprod) => {
                  if (featprod.attributes.feature === true) {
                    return (
                      <div key={featprod.id} className='col-sm-6 col-md-3'>
                        {' '}
                        {/* 1 */}
                        <div
                          className='affa-product animation'
                          data-animation='animation-fade-in-up'
                        >
                          <figure className='post-thumbnail post-item-thumbnail'>
                            {featprod.attributes.image && (
                              <a
                                href={`/ecostore/product/${featprod.attributes.slug}`}
                              >
                                <div className='post-thumbnail-overlay'>
                                  <img
                                    src={
                                      API_URL +
                                      featprod.attributes.image.data[0]
                                        .attributes.formats.large.url
                                    }
                                    alt='Thumbnail'
                                  />
                                </div>
                                <img
                                  src={
                                    API_URL +
                                    featprod.attributes.image.data[0].attributes
                                      .formats.medium.url
                                  }
                                  alt='Thumbnail'
                                />
                              </a>
                            )}
                            <span className='post-thumbnail-badge badge-discount'>
                              <i className='ion ion-ios-star'></i>
                            </span>
                          </figure>
                          <div className='post-item-txt'>
                            <p className='post-item-cats'>
                              <a
                                href={`/ecostore/${featprod.attributes.category.data.attributes.uid}`}
                              >
                                {
                                  featprod.attributes.category.data.attributes
                                    .name
                                }
                              </a>
                            </p>
                            <h4 className='entry-title post-item-title'>
                              <a
                                href={`/ecostore/product/${featprod.attributes.slug}`}
                              >
                                {featprod.attributes.title}
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
            {/* .row end */}

            <div className='post-heading-left3'>
              <h4>
                <strong>Todos os produtos</strong>
              </h4>
            </div>

            {/* .row */}
            <div className='isotope-row row'>
              {dataProducts.data &&
                dataProducts.data.map((allprod) => {
                  return (
                    <div className='col-sm-6 col-md-3'>
                      {' '}
                      {/* 1 */}
                      <div
                        className='affa-product animation'
                        data-animation='animation-fade-in-up'
                      >
                        <figure className='post-thumbnail post-item-thumbnail'>
                          {allprod.attributes.image && (
                            <a
                              href={`/ecostore/product/${allprod.attributes.slug} `}
                            >
                              <div className='post-thumbnail-overlay'>
                                <img
                                  src={
                                    API_URL +
                                    allprod.attributes.image.data[0].attributes
                                      .formats.large.url
                                  }
                                  alt='Thumbnail'
                                />
                              </div>
                              <img
                                src={
                                  API_URL +
                                  allprod.attributes.image.data[0].attributes
                                    .formats.small.url
                                }
                                alt='Thumbnail'
                              />
                            </a>
                          )}
                        </figure>
                        <div className='post-item-txt'>
                          <p className='post-item-cats'>
                            <a
                              href={`/ecostore/${allprod.attributes.category.data.attributes.uid}`}
                            >
                              {' '}
                              {allprod.attributes.category.data.attributes.name}
                            </a>
                          </p>
                          <h4 className='entry-title post-item-title'>
                            <a
                              href={`/ecostore/product/${allprod.attributes.slug} `}
                            >
                              {allprod.attributes.title}
                            </a>
                          </h4>
                          {/* <div className="entry-meta post-item-meta">
                                            <div className="post-meta-rate">
                                                <span className="post-rate-val" style={{ width: '90%' }}>4.5</span>
                                            </div>
                                            <div className="post-meta-price">
                                                <p className="price-original">$149.90</p>
                                                <p className="price-discount">$79.90</p>
                                            </div>
                                        </div>
                                        <a href="#" className="add-cart">
                                            <span className="current">+</span>
                                            <span className="hover">+</span>
                                        </a> */}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            {/* .row end */}
          </div>
          {/* .container end */}

          {/* .bg-grey */}
          <div className='bg-grey padding-top60 padding-bottom60 hide'>
            {/* .container */}
            <div className='container'>
              <div className='post-heading-center'>
                <h4>Our Brands</h4>
                <p>
                  Aenean ut euismod magna donec vel convallis sem fusce euismod
                  purus scelerisque. Vivamus convallis dolor non vehicula
                  fermentum ucras augue ipsum.
                </p>
              </div>

              {/* .carousel-slider */}
              <div className='carousel-slider carousel-draggable carousel-clients row'>
                <div className='col-sm-2'>
                  {' '}
                  {/* 1 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/1.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 2 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/2.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 3 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/3.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 4 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/4.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 5 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/5.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 6 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/6.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 7 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/1.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 8 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/2.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 9 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/3.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 10 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/4.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 11 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/5.png' alt='Logo' />
                  </div>
                </div>

                <div className='col-sm-2'>
                  {' '}
                  {/* 12 */}
                  <div className='affa-client-logo'>
                    <img src='images/content/clients/6.png' alt='Logo' />
                  </div>
                </div>
              </div>
              {/* .carousel-slider end */}
            </div>
            {/* .container end */}
          </div>
          {/* .bg-grey end */}
        </div>
      </div>
    )
  }
}

export default ListProducts
