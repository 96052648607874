import React, { lazy, Suspense } from 'react'
import { ContentProvider } from '../context/ContextProvider'
import {
  Header,
  Navigation,
  NavTop,
  NavMobile,
  NavMobileTop,
  NavBar,
  BodyWrap,
  Footer,
  Bottom,
} from '../config/ComponentsAll'
import NavLinks from '../components/navigation/NavLinks'
import NavMobileLinks from '../components/navigation/NavMobileLinks'
import { PageProvider } from '../context/PageProvider'
import { FooterProvider } from '../context/FooterProvider'
import LoadingGif from '../components/Loading'
//import Highlight from './componentPages/Highlight'
//import Testimonials from './componentPages/Testimonials'
//import Partners from './componentPages/Partners'
//import Features from './componentPages/Features'
//import Team from './componentPages/Team'
const Content = lazy(() => import('./Content'))
const Features = lazy(() => import('./componentPages/Features'))
const Highlight = lazy(() => import('./componentPages/Highlight'))
const Team = lazy(() => import('./componentPages/Team'))
const Testimonials = lazy(() => import('./componentPages/Testimonials'))
const Partners = lazy(() => import('./componentPages/Partners'))
const Pages = ({ children }) => {
  return (
    <ContentProvider>
      <PageProvider>
        <Header>
          <NavMobile>
            <NavMobileLinks />
          </NavMobile>

          <NavMobileTop />

          <Navigation>
            <NavTop />

            <NavBar>
              <NavLinks />
            </NavBar>
          </Navigation>
        </Header>

        <BodyWrap>
          <Suspense fallback={<div></div>}>
            <Content />
            <Features />
            <Highlight />
            <Team />
            <Testimonials />
            <Partners />
          </Suspense>
        </BodyWrap>

        <FooterProvider>
          <Footer>
            <Bottom />
          </Footer>
        </FooterProvider>
      </PageProvider>
    </ContentProvider>
  )
}

export default Pages
