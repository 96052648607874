import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
//import components Globais
import Pages from './pages'
import Ecostore from './pages/Ecostrore'

import Page404 from './pages/Page404'

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<Page404 />} />
          <Route path='/' element={<Pages />} />
          <Route path=':slug' element={<Pages />} />
          <Route path='/ecostore' element={<Ecostore />} />
          <Route path='/ecostore/:category' element={<Ecostore />} />
          <Route path='/ecostore/product/:slug' element={<Ecostore />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
