import React, { Fragment } from 'react'

import SecondaryNav from './SecondaryNav'
import Logo from '../header/Logo'

const NavBar = ({ children }) => {
  return (
    <Fragment>
      {/* .navbar */}
      <div className='navbar'>
        {/* .container */}
        <div className='container'>
          <div className='navbar-wrap'>
            <Logo />

            <div className='navbar-menu'>
              {children}

              <SecondaryNav data={{ visible: 'hide' }} />
            </div>
          </div>
        </div>
        {/* .container end */}
      </div>
      {/* .navbar end */}
    </Fragment>
  )
}

export default NavBar
