import React from 'react'

const Breadcrumb = (props) => {
  const { title, slug } = props
  return (
    <div className='sub-header'>
      <div className='container'>
        <h4 className='header-title'>{title && title}</h4>
        <div className='breadcrumbs'>
          <a href='/'>Home</a> <span className='sep'>/</span>{' '}
          <span className='current'>
            <a href={slug && slug}>{title && title}</a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
