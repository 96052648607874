import axios from 'axios'
import HTMLReactParser from 'html-react-parser'
import React, { useContext, useEffect, useState } from 'react'
import { TOKEN_API } from '../../config/ComponentsAll'
import { PageContext } from '../../context/contextContent'

const FormContact = () => {
  const pageContext = useContext(PageContext)
  const [mensagem, setMensage] = useState('')
  const [title, settitle] = useState([])
  const [subtitle, setsubtitle] = useState([])

  useEffect(() => {
    if (pageContext.related) {
      const { title, subtitle } = pageContext.related
      settitle(title)
      setsubtitle(subtitle)
    }
  }, [pageContext.related])
  function handleSubmit(e) {
    const data = e.currentTarget.elements
    const { name, subject, email, phone, message } = data

    const dataform = {
      name: name.value,
      subject: subject.value,
      email: email.value,
      phone: phone.value,
      mensagem: message.value,
    }

    e.preventDefault()

    axios
      .post('https://api.ecometria.eco.br/api/contacts', {
        data: dataform,

        headers: {
          Authorization: `bearer ${TOKEN_API}`,
        },
      })
      .then(function (response) {
        if (response.status === 200)
          setMensage(
            '<div class="affa-callout callout-success"><h4>Sua mensagem foi enviada com sucesso. Em breve entraremos em contato!</h4></div>',
          )
      })
      .catch(function (response) {
        if (response.status !== 200)
          setMensage(
            '<div class="affa-callout callout-danger"><h4>Houve um erro ao tentar enviar sua mensagem. Por favor tente novamente mais tarde</h4></div>',
          )
      })
  }

  return (
    <form
      method='post'
      onSubmit={handleSubmit}
      className='affa-form-contact form-contact2- margin-bottom50 submit-form-contact'
    >
      {mensagem && HTMLReactParser(mensagem)}
      <div className='row'>
        <div className='col-sm-12'>
          <input
            type='hidden'
            name='subject'
            value={`Contato Ecometria ${
              (title && title) + ' ' + subtitle && subtitle
            }`}
          />
          <input type='text' name='name' required placeholder='Seu nome' />
        </div>
        <div className='col-sm-12'>
          <input
            type='text'
            name='email'
            required
            placeholder='Seu email (*)'
          />
        </div>

        <div className='col-sm-12'>
          <input type='text' name='phone' placeholder='Seu telefone' />
        </div>
      </div>
      <textarea
        name='message'
        id='contact-message'
        placeholder='Sua mensagem (*)'
      ></textarea>
      <div className='text-center'>
        <input type='submit' name='submit' value='Enviar mensagem' />
      </div>
      <div className='submit-status'></div>
    </form>
  )
}

export default FormContact
