import * as type from './contentTypes'

const ContentReducer = (state, action) => {
  // eslint-disable-next-line
  switch (action.type) {
    case type.CONTENT_SUCCESS: {
      // console.log(action.type);
      return { ...state, data: action.payload, loading: false }
    }
    case type.CONTENT_LOAD: {
      return { ...state, loading: true }
    }
  }
  // console.log('Não encontrei a action', action.type)
  return { ...state }
}

export default ContentReducer
